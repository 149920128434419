import { seriesEsp, seriesEng, seriesId, groupsEsp, groupsEng, unitsEsp, unitsEng } from './translations/translations_frp_contabilidad_historica.js'
import { translateSeries } from '../lib/translate_series_name.js'
export default class Visualizer {
  series = [];
  charts = [];

  RANGES_DEFAULT = [];
  CHARTS_DEFAULT = [];

  constructor(params) {
    this.charts = params || [];
    this.setDefaults();

    return {
      charts: this.charts.map(x => Object.assign(this.CHARTS_DEFAULT.find(y => y.type == x.type), x)),
      ranges: this.RANGES_DEFAULT,
      endpoints: {
        series: this.series,
        data: {
          build: () => {
            const sql = 'select c4_exportaciones-c4_importaciones as c4_exp_imp, * from cuentas_historicas_espana ORDER BY year ASC';
            const gobiertoPath = process.env.GOBIERTO_DATA_URL
            const token = process.env.GOBIERTO_DATA_TOKEN
            const query = `?sql=${sql}&token=${token}`;

            return `${gobiertoPath}/api/v1/data/data.json${query}`;
          },
          parse: data => {
            this.series = translateSeries(this.series, seriesEsp, seriesEng, seriesId, unitsEsp, unitsEng, groupsEsp, groupsEng)
            const series = this.series.map(d => ({
              id: d.id,
              name: d.name,
              units: d.units,
              group: d.group
            }));
            // reorder the return data to be grouped by kind of serie
            let groupBySerie = series.map(set =>
              data.data.map(d => ({
                serie: set.id,
                time: new Date(d["year"], 0, 1),
                value: parseFloat(d[set.id]),
                name: set.name,
                units: set.units,
                idTooltip: "ch",
                group: set.group
              }))
            );

            // give a flatted array
            return groupBySerie.flat();
          }
        }
      }
    };
  }

  setDefaults = () => {
    const series = [
      {
        "id": "c0_consumo_privado",
        "name": "Consumo privado ",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_consumo_publico",
        "name": "Consumo público ",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_variacion_existencias",
        "name": "Variación Existencias",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_exportaciones",
        "name": "Exportaciones",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_importaciones",
        "name": "Importaciones",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c0_pib",
        "name": "PIB",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c1_pib",
        "name": "PIB",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_rentas_primarias_netas_rdm",
        "name": "Rentas primarias netas rdm",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_rnb",
        "name": "RNB",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_consumo_de_capital_fijo",
        "name": "Consumo de capital fijo",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_rnn",
        "name": "RNN",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_transferencias_corrientes_netas_rdm",
        "name": "Transferencias corrientes netas rdm",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c1_rndn",
        "name": "RNDN",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta"
      },
      {
        "id": "c2_pib",
        "name": "PIB",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rnb",
        "name": "RNB",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rnn",
        "name": "RNN",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rndn",
        "name": "RNDN",
        "units": "millones de euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_poblacion",
        "name": "Población",
        "units": "millones",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_pib_per_capita",
        "name": "PIB per capita",
        "units": "euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rnb_per_capita",
        "name": "RNB per capita",
        "units": "euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rnn_per_capita",
        "name": "RNN per capita",
        "units": "euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c2_rndn_per_capita",
        "name": "RNDN per capita",
        "units": "euros",
        "group": "Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_pib",
        "name": "PIB",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rnb",
        "name": "RNB",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rnn",
        "name": "RNN",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rndn",
        "name": "RNDN",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_pib_per_capita",
        "name": "PIB per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rnb_per_capita",
        "name": "RNB per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rnn_per_capita",
        "name": "RNN per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c3_rndn_per_capita",
        "name": "RNDN per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto y Renta Nacional Bruta y Neta absoluto y per capita"
      },
      {
        "id": "c4_consumo_privado",
        "name": "Consumo privado ",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c4_consumo_publico",
        "name": "Consumo público ",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c4_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c4_variacion_existencias",
        "name": "Variación Existencias",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c4_exportaciones",
        "name": "Exportaciones",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c4_importaciones",
        "name": "Importaciones",
        "units": "%",
        "group": "Participación de los componentes de gasto en el  Producto Interior Bruto"
      },
      {
        "id": "c5_consumo_privado",
        "name": "Consumo privado ",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_consumo_publico",
        "name": "Consumo público ",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_formacion_capital",
        "name": "Formación capital",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_exportaciones",
        "name": "Exportaciones",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_importaciones",
        "name": "Importaciones",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c5_pib",
        "name": "PIB",
        "units": "2010= 100",
        "group": "Índices de volumen del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_consumo_privado",
        "name": "Consumo privado ",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_consumo_publico",
        "name": "Consumo público ",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_formacion_capital",
        "name": "Formación capital",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_exportaciones",
        "name": "Exportaciones",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_importaciones",
        "name": "Importaciones",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_pib",
        "name": "PIB",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_rnb",
        "name": "RNB",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_rnn",
        "name": "RNN",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c6_rndn",
        "name": "RNDN",
        "units": "2010= 100",
        "group": "Deflactores del Producto Interior Bruto y sus componentes de gasto"
      },
      {
        "id": "c7_viviendas",
        "name": "Viviendas",
        "units": "millones de euros",
        "group": "Formación Bruta de Capital Fijo"
      },
      {
        "id": "c7_otras_construcciones",
        "name": "Otras construcciones",
        "units": "millones de euros",
        "group": "Formación Bruta de Capital Fijo"
      },
      {
        "id": "c7_maquinaria_y_equipo",
        "name": "Maquinaria y equipo",
        "units": "millones de euros",
        "group": "Formación Bruta de Capital Fijo"
      },
      {
        "id": "c7_equipo_de_transporte",
        "name": "Equipo de transporte",
        "units": "millones de euros",
        "group": "Formación Bruta de Capital Fijo"
      },
      {
        "id": "c7_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "millones de euros",
        "group": "Formación Bruta de Capital Fijo"
      },
      {
        "id": "c8_viviendas",
        "name": "Viviendas",
        "units": "%",
        "group": "Composición de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c8_otras_construcciones",
        "name": "Otras construcciones",
        "units": "%",
        "group": "Composición de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c8_maquinaria_y_equipo",
        "name": "Maquinaria y equipo",
        "units": "%",
        "group": "Composición de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c8_equipo_de_transporte",
        "name": "Equipo de transporte",
        "units": "%",
        "group": "Composición de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c9_viviendas",
        "name": "Viviendas",
        "units": "2010= 100",
        "group": "Índices de volumen de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c9_otras_construcciones",
        "name": "Otras construcciones",
        "units": "2010= 100",
        "group": "Índices de volumen de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c9_maquinaria_y_equipo",
        "name": "Maquinaria y equipo",
        "units": "2010= 100",
        "group": "Índices de volumen de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c9_equipo_de_transporte",
        "name": "Equipo de transporte",
        "units": "2010= 100",
        "group": "Índices de volumen de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c9_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "2010= 100",
        "group": "Índices de volumen de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c10_viviendas",
        "name": "Viviendas",
        "units": "2010= 100",
        "group": "Deflactores de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c10_otras_construcciones",
        "name": "Otras construcciones",
        "units": "2010= 100",
        "group": "Deflactores de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c10_maquinaria_y_equipo",
        "name": "Maquinaria y equipo",
        "units": "2010= 100",
        "group": "Deflactores de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c10_equipo_de_transporte",
        "name": "Equipo de transporte",
        "units": "2010= 100",
        "group": "Deflactores de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c10_formacion_bruta_capital_fijo",
        "name": "Formación bruta capital fijo",
        "units": "2010= 100",
        "group": "Deflactores de la Formación Bruta de Capital Fijo"
      },
      {
        "id": "c11_agricultura",
        "name": "Agricultura",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_industria",
        "name": "Industria",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_construccion",
        "name": "Construcción",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_servicios",
        "name": "Servicios",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_valor_anadido_bruto",
        "name": "Valor Añadido Bruto",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_impuestos_netos_sobre_productos",
        "name": "Impuestos netos sobre productos",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c11_pib",
        "name": "PIB",
        "units": "millones de euros",
        "group": "El Producto Interior Bruto y sus componentes de producto"
      },
      {
        "id": "c12_vab",
        "name": "VAB",
        "units": "millones de euros",
        "group": "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante"
      },
      {
        "id": "c12_pib",
        "name": "PIB",
        "units": "millones de euros",
        "group": "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante"
      },
      {
        "id": "c12_poblacion",
        "name": "Población",
        "units": "millones",
        "group": "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante"
      },
      {
        "id": "c12_vab_per_capita",
        "name": "VAB per capita",
        "units": "Euro",
        "group": "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante"
      },
      {
        "id": "c12_pib_per_capita",
        "name": "PIB per capita",
        "units": "Euro",
        "group": "Valor Añadido Bruto y Producto Interior Bruto a precios de mercado absoluto y por habitante"
      },
      {
        "id": "c13_vab",
        "name": "VAB",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor Añadido Bruto absoluto y por habitante"
      },
      {
        "id": "c13_pib",
        "name": "PIB",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor Añadido Bruto absoluto y por habitante"
      },
      {
        "id": "c13_vab_per_capita",
        "name": "VAB per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor Añadido Bruto absoluto y por habitante"
      },
      {
        "id": "c13_pib_per_capita",
        "name": "PIB per capita",
        "units": "2010= 100",
        "group": "Índices de volumen de Producto Interior Bruto a precios de mercado y Valor Añadido Bruto absoluto y por habitante"
      },
      {
        "id": "c14_agricultura",
        "name": "Agricultura",
        "units": "%",
        "group": "Participación de las ramas de actividad en el Valor Añadido Bruto"
      },
      {
        "id": "c14_industria",
        "name": "Industria",
        "units": "%",
        "group": "Participación de las ramas de actividad en el Valor Añadido Bruto"
      },
      {
        "id": "c14_construccion",
        "name": "Construcción",
        "units": "%",
        "group": "Participación de las ramas de actividad en el Valor Añadido Bruto"
      },
      {
        "id": "c14_servicios",
        "name": "Servicios",
        "units": "%",
        "group": "Participación de las ramas de actividad en el Valor Añadido Bruto"
      },
      {
        "id": "c15_agricultura",
        "name": "Agricultura",
        "units": "2010= 100",
        "group": "Índices de volumen de Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c15_industria",
        "name": "Industria",
        "units": "2010= 100",
        "group": "Índices de volumen de Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c15_construccion",
        "name": "Construcción",
        "units": "2010= 100",
        "group": "Índices de volumen de Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c15_servicios",
        "name": "Servicios",
        "units": "2010= 100",
        "group": "Índices de volumen de Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c15_vab",
        "name": "VAB",
        "units": "2010= 100",
        "group": "Índices de volumen de Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c16_agricultura",
        "name": "Agricultura",
        "units": "2010= 100",
        "group": "Deflactores del Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c16_industria",
        "name": "Industria",
        "units": "2010= 100",
        "group": "Deflactores del Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c16_construccion",
        "name": "Construcción",
        "units": "2010= 100",
        "group": "Deflactores del Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c16_servicios",
        "name": "Servicios",
        "units": "2010= 100",
        "group": "Deflactores del Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c16_vab",
        "name": "VAB",
        "units": "2010= 100",
        "group": "Deflactores del Valor Añadido Bruto y sus componentes"
      },
      {
        "id": "c17_agricultura",
        "name": "Agricultura",
        "units": "millones",
        "group": "Empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c17_industria",
        "name": "Industria",
        "units": "millones",
        "group": "Empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c17_construccion",
        "name": "Construcción",
        "units": "millones",
        "group": "Empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c17_servicios",
        "name": "Servicios",
        "units": "millones",
        "group": "Empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c17_total",
        "name": "Total",
        "units": "millones",
        "group": "Empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c18_agricultura",
        "name": "Agricultura",
        "units": "%",
        "group": "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c18_industria",
        "name": "Industria",
        "units": "%",
        "group": "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c18_construccion",
        "name": "Construcción",
        "units": "%",
        "group": "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c18_servicios",
        "name": "Servicios",
        "units": "%",
        "group": "Participación por ramas de actividad en el empleo (equivalente a tiempo completo)"
      },
      {
        "id": "c19_agriculture",
        "name": "Agriculture",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c19_industry",
        "name": "Industry",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c19_construction",
        "name": "Construction ",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c19_services",
        "name": "Services",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB / trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c20_agricultura",
        "name": "Agricultura",
        "units": "2010= 100",
        "group": "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c20_industria",
        "name": "Industria",
        "units": "2010= 100",
        "group": "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c20_construccion",
        "name": "Construcción",
        "units": "2010= 100",
        "group": "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c20_servicios",
        "name": "Servicios",
        "units": "2010= 100",
        "group": "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c20_vab",
        "name": "VAB",
        "units": "2010= 100",
        "group": "Índices de productividad laboral (Valor añadido bruto por trabajador equivalente a tiempo completo)"
      },
      {
        "id": "c21_agricultura",
        "name": "Agricultura",
        "units": "millones",
        "group": "Horas trabajadas"
      },
      {
        "id": "c21_industria",
        "name": "Industria",
        "units": "millones",
        "group": "Horas trabajadas"
      },
      {
        "id": "c21_construccion",
        "name": "Construcción",
        "units": "millones",
        "group": "Horas trabajadas"
      },
      {
        "id": "c21_servicios",
        "name": "Servicios",
        "units": "millones",
        "group": "Horas trabajadas"
      },
      {
        "id": "c21_total",
        "name": "Total",
        "units": "millones",
        "group": "Horas trabajadas"
      },
      {
        "id": "c22_agricultura",
        "name": "Agricultura",
        "units": "%",
        "group": "Participación por ramas de actividad en las horas trabajadas"
      },
      {
        "id": "c22_industria",
        "name": "Industria",
        "units": "%",
        "group": "Participación por ramas de actividad en las horas trabajadas"
      },
      {
        "id": "c22_construccion",
        "name": "Construcción",
        "units": "%",
        "group": "Participación por ramas de actividad en las horas trabajadas"
      },
      {
        "id": "c22_servicios",
        "name": "Servicios",
        "units": "%",
        "group": "Participación por ramas de actividad en las horas trabajadas"
      },
      {
        "id": "c23_agricultura",
        "name": "Agricultura",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB/hora trabajada)"
      },
      {
        "id": "c23_industria",
        "name": "Industria",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB/hora trabajada)"
      },
      {
        "id": "c23_construccion",
        "name": "Construcción",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB/hora trabajada)"
      },
      {
        "id": "c23_servicios",
        "name": "Servicios",
        "units": "",
        "group": "Productividad relativa del trabajo por ramas de actividad (VAB/hora trabajada)"
      },
      {
        "id": "c24_agricultura",
        "name": "Agricultura",
        "units": "2010= 100",
        "group": "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)"
      },
      {
        "id": "c24_industria",
        "name": "Industria",
        "units": "2010= 100",
        "group": "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)"
      },
      {
        "id": "c24_construccion",
        "name": "Construcción",
        "units": "2010= 100",
        "group": "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)"
      },
      {
        "id": "c24_servicios",
        "name": "Servicios",
        "units": "2010= 100",
        "group": "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)"
      },
      {
        "id": "c24_vab",
        "name": "VAB",
        "units": "2010= 100",
        "group": "Niveles de productividad laboral (Valor Añadido Bruto por hora trabajada)"
      },
      {
        "id": "c25_agricultura",
        "name": "Agricultura",
        "units": "",
        "group": "Horas trabajadas por trabajador equivalente a tiempo completo /año"
      },
      {
        "id": "c25_industria",
        "name": "Industria",
        "units": "",
        "group": "Horas trabajadas por trabajador equivalente a tiempo completo /año"
      },
      {
        "id": "c25_construccion",
        "name": "Construcción",
        "units": "",
        "group": "Horas trabajadas por trabajador equivalente a tiempo completo /año"
      },
      {
        "id": "c25_servicios",
        "name": "Servicios",
        "units": "",
        "group": "Horas trabajadas por trabajador equivalente a tiempo completo /año"
      },
      {
        "id": "c25_total",
        "name": "Total",
        "units": "",
        "group": "Horas trabajadas por trabajador equivalente a tiempo completo /año"
      },
      {
        "id": "c26_espana",
        "name": "España",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c26_italia",
        "name": "Italia",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c26_francia",
        "name": "Francia",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c26_reino_unido",
        "name": "Reino Unido",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c26_ee_uu",
        "name": "EE.UU.",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c26_alemania",
        "name": "Alemania",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_espana",
        "name": "España",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_italia",
        "name": "Italia",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_francia",
        "name": "Francia",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_reino_unido",
        "name": "Reino Unido",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_ee_uu",
        "name": "EE.UU.",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      },
      {
        "id": "c27_alemania",
        "name": "Alemania",
        "units": "",
        "group": "Producto Interior Bruto Real per capita"
      }
    ];

    if (this.charts[0].group) {
      const arrayOfGroup = this.charts[0].group
      this.series = series.filter((d) => arrayOfGroup.includes(d.group))
    } else {
      this.series = series
    }

    const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    this.RANGES_DEFAULT = range(1850, 2023);

    this.CHARTS_DEFAULT = [
      {
        type: "Line",
        btnPlaceholder: "Añadir serie",
        xAxisProp: "time",
        yAxisProp: "value",
        aggregationProp: "serie",
        tooltip: this.tooltipCommon,
        language: 'es',
        bspline: false,
        cache: true,
        ticksXAxis: 30,
        tickFormatY: (...args) => {
          const [d, , , d3] = args;

          return d < 1e3 ? d3.format("")(d) : d3.format("~s")(d);
        }
      },
      {
        type: "Stacked",
        btnPlaceholder: "Añadir serie",
        xAxisProp: "time",
        yAxisProp: "value",
        aggregationProp: "serie",
        tooltip: this.tooltipCommon,
        language: 'es',
        cache: true,
        ticksXAxis: 30,
        tickFormatY: (...args) => {
          const [d, , , d3] = args;

          return d < 1e3 ? d3.format("")(d) : d3.format("~s")(d);
        }
      }
    ];
  };
  getScale(key, d3) {
    const scales = {
      linear: d3.scaleLinear(),
      log: d3.scaleLog(),
      pow: d3.scalePow()
    };

    if (scales.hasOwnProperty(key)) {
      return scales[key];
    } else {
      return scales[0];
    }
  }

}
